import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoadTop from "../components/LoadTop";
import BackToTop from "../components/BackToTop";
import HomeOne from "./HomeOne";

// inner pages
import OurService from "../inner/OurService";
import ServiceDetails from "../inner/ServiceDetails";
import Appoinment from "../inner/Appoinment";
import AboutUs from "../inner/AboutUs";
import PricingPlane from "../inner/PricingPlane";
import TestimonialsOne from "../inner/TestimonialsOne";
import Error from "../inner/Error";
import ProjectThree from "../inner/ProjectThree";

import ProjectDetails from "../inner/ProjectDetails";
import Team from "../inner/Team";
import TeamDetails from "../inner/TeamDetails";
import BlogList from "../inner/BlogList";
import BlogGrid from "../inner/BlogGrid";
import BlogDetails from "../inner/BlogDetails";
import BlogDetailsDefault from "../inner/BlogDetailsDefault";
import ContactUs from "../inner/ContactUs";

// onepage
import OnepageOne from "../onepage/OnepageOne";

function RouterPage() {
  return (
    <div>
      <Router>
        <LoadTop />
        <Routes>
          <Route path="/" element={<HomeOne />}></Route>

          <Route path="/our-service" element={<OurService />}></Route>

          <Route path="/service-details" element={<ServiceDetails />}></Route>
          <Route path="/appoinment" element={<Appoinment />}></Route>
          <Route path="/about-us" element={<AboutUs />}></Route>
          <Route path="/pricing-plane" element={<PricingPlane />}></Route>
          <Route
            path="/testimonial-style-1"
            element={<TestimonialsOne />}
          ></Route>
          <Route path="/404" element={<Error />}></Route>
          <Route path="/portfolio-style-3" element={<ProjectThree />}></Route>
          <Route path="/project-details" element={<ProjectDetails />}></Route>
          <Route path="/team" element={<Team />}></Route>
          <Route path="/team-details" element={<TeamDetails />}></Route>
          <Route path="/blog-list" element={<BlogList />}></Route>
          <Route path="/blog-grid" element={<BlogGrid />}></Route>
          <Route path="/blog-details" element={<BlogDetails />}></Route>
          <Route path="/blog/:id" element={<BlogDetails />}></Route>
          <Route
            path="/blog-details-default"
            element={<BlogDetailsDefault />}
          ></Route>
          <Route path="/contactus" element={<ContactUs />}></Route>
          {/* onepage */}
          <Route path="/onepage-one" element={<OnepageOne />}></Route>
        </Routes>
        <BackToTop />
      </Router>
    </div>
  );
}

export default RouterPage;
