
import RouterPage from './home/Routerpage';
import * as React from 'react'
import './App.css';

function App() {
  return (
      <div className="App">
        <RouterPage />
      </div>
    );
}

export default App;
