import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "./Nav";
import SideMenu from "./SideMenu";

function HeaderOne() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const [isSearchVisible, setSearchVisible] = useState(false);

  const handleSearchClick = () => {
    setSearchVisible(true);
  };

  const handleCloseClick = () => {
    setSearchVisible(false);
  };

  const handleBackgroundClick = () => {
    setSearchVisible(false);
  };
  return (
    <div>
      {/* start header area */}
      <header
        className={`header--sticky header-one  ${isSticky ? "sticky" : ""}`}
      >
        <div className="header-top header-top-one bg-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 d-xl-block d-none">
                <div className="left">
                  <div className="mail">
                    <Link to={"mailto:connect@verdeshell.com"}>
                      <i className="fal fa-envelope" /> connect@verdeshell.com
                    </Link>
                  </div>
                  <div className="working-time">
                    <p>
                      <i className="fal fa-clock" /> Working: 9.00am - 7.00pm
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 d-xl-block d-none">
                <div className="right">
                  <ul className="top-nav">
                    <li>
                      <Link to={"/about-us"}>About</Link>
                    </li>
                    <li>
                      <Link to={"/blog-list"}>News</Link>
                    </li>
                    <li>
                      <Link to={"/contactus"}>Contact</Link>
                    </li>
                  </ul>
                  <ul className="social-wrapper-one">
                    <li>
                      <Link to={"#"}>
                        <i className="fab fa-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link to={"#"}>
                        <i className="fab fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link to={"#"}>
                        <i className="fab fa-instagram" />
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="mr--0"
                        target="_blank"
                        to={"https://www.linkedin.com/company/verdeshell"}
                      >
                        <i className="fab fa-linkedin-in" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="header-main-one bg-white">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-4 col-sm-4 col-4">
                <div className="thumbnail">
                  <Link to={"/"}>
                    <img
                      src="/assets/images/logo/verdeshell_logo.svg"
                      alt="verdeshell"
                      width={150}
                      height={75}
                    />
                  </Link>
                </div>
              </div>
              <div className=" col-xl-9 col-lg-8 col-md-8 col-sm-8 col-8">
                <div className="main-header">
                  <Nav />
                  <div className="button-area">
                    <button
                      id="menu-btn"
                      className="menu rts-btn btn-primary-alta ml--20 ml_sm--5"
                      onClick={toggleSidebar}
                    >
                      <img
                        className="menu-dark"
                        src="/assets/images/icon/menu.png"
                        alt="Menu-icon"
                      />
                      <img
                        className="menu-light"
                        src="/assets/images/icon/menu-light.png"
                        alt="Menu-icon"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* End header area */}
      <div className={`search-input-area ${isSearchVisible ? "show" : ""}`}>
        <div className="container">
          <div className="search-input-inner">
            <div className="input-div">
              <input
                id="searchInput1"
                className="search-input"
                type="text"
                placeholder="Search by keyword or #"
              />
              <button>
                <i className="far fa-search" />
              </button>
            </div>
          </div>
        </div>
        <div
          id="close"
          className="search-close-icon"
          onClick={handleCloseClick}
        >
          <i className="far fa-times" />
        </div>
      </div>

      <div
        id="anywhere-home"
        className={isSearchVisible ? "bgshow" : ""}
        onClick={handleBackgroundClick}
      ></div>

      <SideMenu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    </div>
  );
}

export default HeaderOne;
