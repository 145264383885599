import React from "react";
import HeaderOne from "../components/header/HeaderOne";
import { Link } from "react-router-dom";
import FooterOne from "../components/footer/FooterOne";
import Breadcrumb from "./Breadcrumb";

function ContactUs() {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "Contact Us" }];
  return (
    <div className="">
      <HeaderOne />

      <Breadcrumb title="Contact Us" breadcrumbs={breadcrumbs} />

      {/* contact single area start */}
      <div className="rts-contact-area rts-section-gap">
        <div className="container">
          <div className="row g-5">
            {/* single contact area */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="single-contact-one-inner">
                <div className="thumbnail">
                  <img src="assets/images/contact/01.png" alt="" />
                </div>
                <div className="content">
                  <div className="icone">
                    <img src="assets/images/contact/shape/01.svg" alt="" />
                  </div>
                  <div className="info">
                    <span>Call Us</span>
                    <Link to={"tel:+919289355575"}>
                      <h5>+91 9289355575</h5>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* single contact area end */}
            {/* single contact area */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="single-contact-one-inner">
                <div className="thumbnail">
                  <img src="assets/images/contact/02.png" alt="" />
                </div>
                <div className="content">
                  <div className="icone">
                    <img src="assets/images/contact/shape/02.svg" alt="" />
                  </div>
                  <div className="info">
                    <span>MAke A Quote</span>
                    <Link to={"mailto:connect@verdeshell.com"}>
                      <h5>connect@verdeshell.com</h5>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* single contact area end */}
            {/* single contact area */}
            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
              <div className="single-contact-one-inner">
                <div className="thumbnail">
                  <img src="assets/images/contact/03.png" alt="" />
                </div>
                <div className="content">
                  <div className="icone">
                    <img src="assets/images/contact/shape/03.svg" alt="" />
                  </div>
                  <div className="info">
                    <span>Service Station</span>
                    <Link to={"#"}>
                      <h5>Sec 58, Gurgaon, Haryana</h5>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* single contact area end */}
          </div>
        </div>
      </div>
      {/* conact single area end */}

      {/* conact us form fluid start */}
      <div className="rts-contact-form-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="rts-contact-fluid rts-section-gap">
                <div className="rts-title-area contact-fluid text-center mb--50">
                  <p className="pre-title">Get In Touch</p>
                  <h2 className="title">Needs Help? Let’s Get in Touch</h2>
                </div>
                <div className="form-wrapper">
                  <div id="form-messages" />
                  <form id="contact-form">
                    <div className="name-email">
                      <input
                        type="text"
                        name="name"
                        placeholder="Your Name"
                        required=""
                      />
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        required=""
                      />
                    </div>
                    <input
                      type="text"
                      name="subject"
                      placeholder="Your Subject"
                    />
                    <textarea
                      placeholder="Type Your Message"
                      name="message"
                      defaultValue={""}
                    />
                    <button type="submit" className="rts-btn btn-primary">
                      Send Message
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* conact us form fluid end */}

      <FooterOne />
    </div>
  );
}

export default ContactUs;
